import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/api";

const initialState = {
   list: [],
   fetching: true,
   search: ''
}

export const fetchGroupList = createAsyncThunk(
   'group/fetchGroupList',
   async (param = "", { getState }) => {
      const { search } = getState().group;
      const result = await getRequest(`/group${param}&search=${search}`);
      return result;
   }
);

export const fetchGroupItem = createAsyncThunk(
   'group/fetchGroupItem',
   async (id) => {
      const result = await getRequest(`/group/${id}`);
      return result;
   }
)

export const insertGroup = createAsyncThunk(
   'group/insertGroup',
   async (data, { dispatch }) => {
      const result = await postRequest(`/group`, data);
      dispatch(addItem(result.data));
      return result;
   }
)

export const updateGroup = createAsyncThunk(
   'group/updateGroup',
   async (data, { dispatch }) => {
      const { id } = data;
      const result = await putRequest(`/group/${id}`, data);
      dispatch(updateItem(result.data));
      return result;
   }
)

export const deleteGroup = createAsyncThunk(
   'group/deleteGroup',
   async (id, { dispatch, getState }) => {
      const data = getState().group.list?.data.find(item => item.id === id);
      const result = await deleteRequest(`/group/${id}`);
      dispatch(removeItem(data));
      return result;
   }
);

const groupSlice = createSlice({
   name: 'group',
   initialState,
   reducers: {
      setList: (state, action) => {
         state.list = action.payload
      },
      addItem: (state, action) => {
         const data = [action.payload, ...state.list.data];
         state.list.data = data;
      },
      removeItem: (state, action) => {
         const removedData = state.list.data.filter((item) => item.id !== action.payload.id);
         state.list.data = removedData;
      },
      updateItem: (state, action) => {
         const data = state.list.data;
         const updatedData = data.map((item) => {
            if (item.id === action.payload.id) {
               return { ...action.payload };
            }
            return item;
         });
         state.list.data = updatedData;
      },
      setSearch: (state, action) => {
         state.search = action.payload;
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchGroupList.fulfilled, (state, action) => {
            state.fetching = false;
            state.list = action.payload;
         })
         .addCase(fetchGroupList.rejected, (state) => {
            state.fetching = false;
         });
   }
});

export const { setList, addItem, removeItem, updateItem, setSearch } = groupSlice.actions;
export default groupSlice.reducer;