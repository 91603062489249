import Swal from 'sweetalert2'

const toast = (type = "success", message, duration = 4000) => {
    Swal.fire({
        toast: true,
        timer: duration, 
        position: 'bottom',
        html: `<span>${message}</span>`,
        showConfirmButton: false,
        customClass: {
            popup: `p-0 rounded-pill shadow-sm border bg-dark`,
            htmlContainer: `rounded-pill py-1 text-center text-white fs-7`
        }
    })
};

export default toast