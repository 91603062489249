export const saveStorage = (name, value) => {
    let data = JSON.stringify(value);
    window.localStorage.setItem(name, data);
}

export const getStorage = (name) => {
    let data = JSON.parse(window.localStorage.getItem(name));
    return data;
}

export const removeStorage = (name) => {
    window.localStorage.removeItem(name);
}