import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/api";

const initialState = {
   list: [],
   fetching: true,
   search: ''
}

export const fetchTagList = createAsyncThunk(
   'tag/fetchTagList',
   async (param = "", { getState }) => {
      const { search } = getState().tag;
      const result = await getRequest(`/tag${param}&search=${search}`);
      return result;
   }
);

export const fetchTagItem = createAsyncThunk(
   'tag/fetchTagItem',
   async (id) => {
      const result = await getRequest(`/tag/${id}`);
      return result;
   }
)

export const insertTag = createAsyncThunk(
   'tag/insertTag',
   async (data, { dispatch }) => {
      const result = await postRequest(`/tag`, data);
      dispatch(addItem(result.data));
      return result;
   }
)

export const updateTag = createAsyncThunk(
   'tag/updateTag',
   async (data, { dispatch }) => {
      const { id } = data;
      const result = await putRequest(`/tag/${id}`, data);
      dispatch(updateItem(result.data));
      return result;
   }
)

export const deleteTag = createAsyncThunk(
   'tag/deleteTag',
   async (id, { dispatch, getState }) => {
      const data = getState().tag.list.data.find(item => item.id === id);
      const result = await deleteRequest(`/tag/${id}`);
      dispatch(removeItem(data));
      return result;
   }
);

const tagSlice = createSlice({
   name: 'tag',
   initialState,
   reducers: {
      setList: (state, action) => {
         state.list = action.payload
      },
      addItem: (state, action) => {
         const data = [action.payload, ...state.list.data];
         state.list.data = data;
      },
      removeItem: (state, action) => {
         const removedData = state.list.data.filter((item) => item.id !== action.payload.id);
         state.list.data = removedData;
      },
      updateItem: (state, action) => {
         const data = state.list.data;
         const updatedData = data.map((item) => {
            if (item.id === action.payload.id) {
               return { ...action.payload };
            }
            return item;
         });
         state.list.data = updatedData;
      },
      setSearch: (state, action) => {
         state.search = action.payload
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchTagList.fulfilled, (state, action) => {
            state.fetching = false;
            state.list = action.payload;
         })
         .addCase(fetchTagList.rejected, (state) => {
            state.fetching = false;
         });
   }
});

export const { setList, addItem, removeItem, updateItem, setSearch } = tagSlice.actions;
export default tagSlice.reducer;