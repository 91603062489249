import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import LinkContainer from 'react-router-bootstrap/LinkContainer'
import { FiUser, FiLogOut, FiMonitor } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import menuList from '../data/navmenu';
import useAuthPermission from '../../hooks/useAuthPermission';

function TopNav() {
    const { user } = useSelector(state => state.auth);
    const { permissionCheck, dropdownPermissionCheck } = useAuthPermission();

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="shadow-sm" sticky="top">
            <Container fluid className="px-lg-5">
                <Navbar.Brand>
                    <div className="d-flex align-items-center">
                        <FiMonitor className="me-2" />
                        <span>Core Monitoring</span>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="shadow-none ms-auto" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-lg-auto py-3 py-lg-0">
                        {menuList.map((item, index) => (
                            (item.dropdown ? (
                                (dropdownPermissionCheck(item.menu) && (
                                    <NavDropdown 
                                        key={index} 
                                        title={item.title} 
                                        id={item.id} 
                                        className="border-0">
                                        {item.menu.map((dropMenu, dropIndex) => (
                                            (permissionCheck(dropMenu.name) && (
                                                <NavLink 
                                                    key={dropIndex} 
                                                    path={dropMenu.url} 
                                                    text={dropMenu.label} 
                                                    Icon={dropMenu.icon} />
                                            ))
                                        ))}
                                    </NavDropdown>
                                ))
                            ) : (
                                (permissionCheck(item.name) && (
                                    <TopNavLink key={index} to={item.url}>
                                        {item.icon}
                                        <span>{item.label}</span>
                                    </TopNavLink>
                                ))
                            ))
                        ))}
                    </Nav>
                    <Nav className="ms-auto">
                        <NavDropdown title={`${user?.data.fullname}`} id="dropdown-profile" align="end">
                            <NavLink path="/profile" text="My Profile" Icon={FiUser} />
                            <NavDropdown.Divider />
                            <NavLink path="/logout" text="Logout" Icon={FiLogOut} className="text-danger" />
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

function TopNavLink({ children, to, ...props }) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });
    const linkClass = `d-flex me-md-4 text-decoration-none justify-content-lg-center align-items-center p-2 px-1 p-lg-0 ${match ? 'text-white' : 'text-muted'}`

    return (
        <Link {...props} to={to} className={linkClass}>
            {children}
        </Link>
    )
}

function NavLink({ path, text, Icon, className = "" }) {
    return (
        <LinkContainer to={path}>
            <NavDropdown.Item className="px-4 py-2">
                <div className={`d-flex justify-content-start align-items-center ${className}`}>
                    <div className="me-3">
                        <Icon style={{ marginTop: -2 }} />
                    </div>
                    <div>
                        {text}
                    </div>
                </div>
            </NavDropdown.Item>
        </LinkContainer>
    )
}

export default TopNav
