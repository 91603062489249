import axios from "axios";
import { io } from 'socket.io-client';
// import { parseError } from "./helper";

const socketUri =
  process.env.NODE_ENV === "development"
    ? "ws://localhost:4000"
    : "https://backend.edubridgeinfinite.my.id";
const apiUri =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/api"
    : "https://backend.edubridgeinfinite.my.id/api";

export const socket = io(socketUri);
const api = axios.create({
    baseURL: apiUri,
    timeout: 30000,
    timeoutErrorMessage: 'Server not responding',
    withCredentials: true,
});

export const getRequest = async (url) => {
    try {
        const result = await api.get(url);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const postRequest = async (url, data) => {
    try {
        const result = await api.post(url, data);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const putRequest = async (url, data) => {
    try {
        const result = await api.put(url, data);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const patchRequest = async (url, data) => {
    try {
        const result = await api.patch(url, data);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const deleteRequest = async (url) => {
    try {
        const result = await api.delete(url);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export default api;
