import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/api";
import { authLogout } from "./auth";

const initialState = {
   list: [],
   fetching: true,
   search: ''
}

export const fetchUserList = createAsyncThunk(
   'user/fetchUserList',
   async (param = "", { getState }) => {
      const { search } = getState().user;
      const result = await getRequest(`/user${param}&search=${search}`);
      return result;
   }
);

export const fetchUserItem = createAsyncThunk(
   'user/fetchUserItem',
   async (id) => {
      const result = await getRequest(`/user/${id}`);
      return result;
   }
)

export const insertUser = createAsyncThunk(
   'user/insertUser',
   async (data, { dispatch, rejectWithValue }) => {
      try {
         const result = await postRequest(`/user`, data);
         dispatch(addItem(result.data));
         return result;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
)

export const updateUser = createAsyncThunk(
   'user/updateUser',
   async (data, { getState, dispatch, rejectWithValue }) => {
      try {
         const { user } = getState().auth;
         const { id } = data;
         const result = await putRequest(`/user/update/${id}`, data);
         dispatch(updateItem(result.data));
         if (user.data.id === id) {
            setTimeout(() => {
               dispatch(authLogout());
            }, 1000);
         }
         return result;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
)

export const resetPassword = createAsyncThunk(
   'user/resetPassword',
   async (data, { rejectWithValue }) => {
      try {
         const { id } = data;
         const result = await putRequest(`/user/password/${id}/reset`, data);
         return result;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
)

export const deleteUser = createAsyncThunk(
   'user/deleteUser',
   async (id, { dispatch, getState, rejectWithValue }) => {
      try {
         const data = getState().user.list?.data.find(item => item.id === id);
         const result = await deleteRequest(`/user/${id}`);
         dispatch(removeItem(data));
         return result;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      setList: (state, action) => {
         state.list = action.payload
      },
      addItem: (state, action) => {
         const data = [action.payload, ...state.list.data];
         state.list.data = data;
      },
      removeItem: (state, action) => {
         const removedData = state.list.data.filter((item) => item.id !== action.payload.id);
         state.list.data = removedData;
      },
      updateItem: (state, action) => {
         const data = state.list.data;
         const updatedData = data.map((item) => {
            if (item.id === action.payload.id) {
               return { ...action.payload };
            }
            return item;
         });
         state.list.data = updatedData;
      },
      setSearch: (state, action) => {
         state.search = action.payload;
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchUserList.fulfilled, (state, action) => {
            state.fetching = false;
            state.list = action.payload;
         })
         .addCase(fetchUserList.rejected, (state) => {
            state.fetching = false;
         });
   }
});

export const { setList, addItem, removeItem, updateItem, setSearch } = userSlice.actions;
export default userSlice.reducer;