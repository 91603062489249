import toast from "./toast";

export function ipv4(message = "Invalid IP address") {
  return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message,
    excludeEmptyString: true,
  }).test("ip", message, (value) => {
    return value === undefined || value.trim() === ""
      ? true
      : value.split(".").find((i) => parseInt(i, 10) > 255) === undefined;
  });
}

export function parseError(error) {
  return typeof error === "string" ? error : Object.values(error)[0]
}

export function ucFirst(str) {
  return (str.charAt(0)).toUpperCase() + str.substring(1);
}

export function getParams(name) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export async function copyToClipboard(copyText) {
  if ('clipboard' in navigator) {
    toast('success', `Copied: ${copyText}`, 2000);
    return await navigator.clipboard.writeText(copyText);
  } else {
    return null;
  }
}