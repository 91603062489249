import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, putRequest } from "../../utils/api";
import { getParams } from "../../utils/helper";
import moment from 'moment'

const initDate = {
  start: moment().startOf('month').format("YYYY-MM-DD"),
  end: moment().endOf('month').format("YYYY-MM-DD")
}

const initialState = {
  list: [],
  fetching: true,
  search: "",
  filter: {
    group_id: "",
    tags: "",
    user_id: "",
    date: initDate
  }
};

export const fetchLogList = createAsyncThunk(
  "log/fetchLogList",
  async (param, { getState }) => {
    const deviceId = getParams('device_id') ? `&device_id=${getParams('device_id')}` : '';
    const { search, filter: { group_id, tags, date, user_id }, } = getState().log;
    const result = await getRequest(
      `/log${param}&search=${search}&user_id=${user_id}&group_id=${group_id}&tags=${tags}${deviceId}&date_range=${date.start} ~ ${date.end}`
    );
    return result;
  }
);

export const fetchLogItem = createAsyncThunk("log/fetchLogItem", async (id) => {
  const result = await getRequest(`/log/${id}`);
  return result;
});

export const updateLog = createAsyncThunk(
  "log/updateLog",
  async (data, { dispatch }) => {
    const { id } = data;
    const result = await putRequest(`/log/update/${id}`, data);
    dispatch(updateItem(result.data));
    return result;
  }
);

export const bulkUpdateLog = createAsyncThunk(
  "log/bulkUpdateLog",
  async (data) => {
    const result = await putRequest(
      `/log/bulk/update?id=${data.ids}`,
      data.form
    );
    return result;
  }
);

const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    updateItem: (state, action) => {
      const data = state.list.data;
      const updatedData = data.map((item) => {
        if (item.id === action.payload.id) {
          return { ...action.payload };
        }
        return item;
      });
      state.list.data = updatedData;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;
    },
    setDateFilter: (state, action) => {
      state.filter.date[action.payload.name] = action.payload.value;
    },
    resetFilter: (state) => {
      state.search = "";
      state.filter = {
        group_id: "",
        tags: "",
        user_id: "",
        date: initDate
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogList.fulfilled, (state, action) => {
        state.fetching = false;
        state.list = action.payload;
      })
      .addCase(fetchLogList.rejected, (state) => {
        state.fetching = false;
      });
  },
});

export const { setList, updateItem, setSearch, setFilter, resetFilter, setDateFilter } = logSlice.actions;
export default logSlice.reducer;
