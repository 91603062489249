import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Toast, ToastContainer } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom'
import TopNav from './TopNav'
import { IoRocketOutline } from 'react-icons/io5'
import { AnimatePresence, motion } from 'framer-motion'
import useScroll from '../../hooks/useScroll'
import addNotification from 'react-push-notification'
import { socket } from '../../utils/api';
import onlineSound from '../../audio/online.wav';
import offlineSound from '../../audio/offline.mp3';
import api from '../../utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { authLogout } from '../../redux/modules/auth'
import { setList } from '../../redux/modules/monitor'
import moment from 'moment'

function Main() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onlineRef = useRef();
    const offlineRef = useRef();
    const [scrollAnimation, showScroll] = useScroll();
    const [showToast, setShowToast] = useState(false);
    const [showBulkToast, setShowBulkToast] = useState(false);
    const [toastData, setToastData] = useState('');
    const [toastBulkData, setToastBulkData] = useState('');
    const { list: monitorList } = useSelector(state => state.monitor);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0 });
    }

    const showNotification = (data) => {
        addNotification({
            title: data.status ? 'Device online' : 'Device offline',
            message: `${data.name} is ${data.status ? 'online' : 'offline'} now`,
            theme: data.status ? 'darkblue' : 'red',
            native: true,
            duration: 4000
        });
        setShowToast(true);
        setToastData({
            status: data.status,
            title: data.status ? 'Device online' : 'Device offline',
            times: moment(data.lastping).fromNow(),
            deviceName: data.name,
            deviceAddress: data.host
        });

        if (data.status) {
            onlineRef.current?.play()
        } else {
            offlineRef.current?.play()
        }
    }

    const showBulkNotification = (data) => {
        addNotification({
            title: `Device ${data.status ? 'online' : 'offline'}`,
            message: `${data.total} is ${data.status ? 'online' : 'offline'} now`,
            theme: data.status ? 'darkblue' : 'red',
            native: true,
            duration: 4000
        });
        setShowBulkToast(true);
        setToastBulkData({
            status: data.status,
            title: `Device ${data.status ? 'online' : 'offline'}`,
            times: moment().fromNow(),
            body: `There are ${data.total} devices is ${data.status ? 'online' : 'offline'} now`
        });

        if (data.status) {
            onlineRef.current?.play();
        } else {
            offlineRef.current?.play();
        }
    }

    useEffect(() => {
        socket.on('updatedDevice', (data) => {
            showNotification(data)
        });

        socket.on('refreshMonitor', (data) => {
            if (data.online.length || data.offline.length) {
                dispatch(setList(data));
            }
        });

        api.interceptors.response.use(async (res) => {
            return res;
        }, async (error) => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(authLogout());
            }
            return Promise.reject(error);
        });
    }, [dispatch, navigate]);

    useEffect(() => {
        if (monitorList.online.length) {
            showBulkNotification({
                total: monitorList.online.length,
                status: 1
            });
        }

        if (monitorList.offline.length) {
            showBulkNotification({
                total: monitorList.offline.length,
                status: 0
            });
        }
    }, [monitorList]);

    return (
        <div id="main-layout">
            <TopNav />
            <Container fluid className="mb-5 px-lg-5 mt-3">
                <Outlet />
            </Container>
            <AnimatePresence exitBeforeEnter={true}>
                {showScroll && (
                    <motion.div
                        style={styles.scrollWrapper}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={scrollAnimation}>
                        <Button variant="primary" className="rounded-circle" style={styles.scrollTop} onClick={scrollToTop}>
                            <IoRocketOutline style={styles.scrollTopIcon} />
                        </Button>
                    </motion.div>
                )}
            </AnimatePresence>

            <ToastContainer position="bottom-end" className="position-fixed m-3">
                <Toast
                    onClose={() => setShowToast(false)}
                    delay={5000}
                    autohide
                    show={showToast}>
                    <Toast.Header>
                        <div className={`rounded me-2 ${toastData.status ? 'bg-success' : 'bg-danger'}`} style={{ width: 10, height: 10 }}></div>
                        <strong className="me-auto">{toastData.title}</strong>
                        <small className="text-muted">{toastData.times}</small>
                    </Toast.Header>
                    <Toast.Body className="bg-white">
                        <div>{toastData.deviceName}</div>
                        <div>{toastData.deviceAddress}</div>
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            <ToastContainer position="bottom-end" className="position-fixed m-3">
                <Toast
                    onClose={() => setShowBulkToast(false)}
                    delay={5000}
                    autohide
                    show={showBulkToast}>
                    <Toast.Header>
                        <div className={`rounded me-2 ${toastBulkData.status ? 'bg-success' : 'bg-danger'}`} style={{ width: 10, height: 10 }}></div>
                        <strong className="me-auto">{toastBulkData.title}</strong>
                        <small className="text-muted">{toastBulkData.times}</small>
                    </Toast.Header>
                    <Toast.Body className="bg-white">
                        <div>{toastBulkData.body}</div>
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {/* Online Sound */}
            <audio autoPlay={false} ref={onlineRef}>
                <source src={onlineSound} type="audio/wav" />
            </audio>

            {/* Offline Sound */}
            <audio autoPlay={false} ref={offlineRef}>
                <source src={offlineSound} type="audio/mp3" />
            </audio>
        </div>
    )
}

const styles = {
    scrollWrapper: {
        position: 'fixed',
        bottom: 0,
        right: 0
    },
    scrollTop: {
        paddingTop: 10,
        paddingBottom: 10,
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: 16
    },
    scrollTopIcon: {
        fontSize: 24,
        transform: 'rotate(-45deg)'
    }
}

export default Main
