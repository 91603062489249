import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { getRequest } from "../../utils/api";

const initDate = {
   start: moment().startOf('month').format("YYYY-MM-DD"),
   end: moment().endOf('month').format("YYYY-MM-DD")
}

const initialState = {
   list: [],
   fetching: true,
   search: '',
   filter: {
      type: '',
      user_id: '',
      date: initDate
   }
}

export const fetchUserActivityList = createAsyncThunk(
   'userActivity/fetchUserActivityList',
   async (param = "", { getState }) => {
      const { search, filter: { type, user_id, date } } = getState().userActivity;
      const result = await getRequest(`/user-activity${param}&search=${search}&type=${type}&user_id=${user_id}&date_range=${date.start} ~ ${date.end}`);
      return result;
   }
);

export const fetchUserActivityItem = createAsyncThunk(
   'userActivity/fetchUserActivityItem',
   async (id) => {
      const result = await getRequest(`/user-activity/${id}`);
      return result;
   }
)

const userActivitySlice = createSlice({
   name: 'userActivity',
   initialState,
   reducers: {
      setList: (state, action) => {
         state.list = action.payload
      },
      addItem: (state, action) => {
         const data = [action.payload, ...state.list.data];
         state.list.data = data;
      },
      removeItem: (state, action) => {
         const removedData = state.list.data.filter((item) => item.id !== action.payload.id);
         state.list.data = removedData;
      },
      updateItem: (state, action) => {
         const data = state.list.data;
         const updatedData = data.map((item) => {
            if (item.id === action.payload.id) {
               return { ...action.payload };
            }
            return item;
         });
         state.list.data = updatedData;
      },
      setSearch: (state, action) => {
         state.search = action.payload;
      },
      setFilter: (state, action) => {
         state.filter[action.payload.name] = action.payload.value;
      },
      setDateFilter: (state, action) => {
         state.filter.date[action.payload.name] = action.payload.value;
      },
      resetFilter: (state) => {
         state.search = "";
         state.filter = {
            user_id: "",
            type: "",
            date: initDate
         };
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchUserActivityList.fulfilled, (state, action) => {
            state.fetching = false;
            state.list = action.payload;
         })
         .addCase(fetchUserActivityList.rejected, (state) => {
            state.fetching = false;
         });
   }
});

export const { setList, addItem, removeItem, updateItem, setSearch, setDateFilter, setFilter, resetFilter } = userActivitySlice.actions;
export default userActivitySlice.reducer;