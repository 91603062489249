import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   list: {
      online: [],
      offline: [],
   }
}

const monitorSlice = createSlice({
   name: 'monitor',
   initialState,
   reducers: {
      setList: (state, action) => {
         state.list = action.payload
      }
   }
});

export const { setList } = monitorSlice.actions;
export default monitorSlice.reducer;