import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { authLogout } from '../redux/modules/auth'

function Logout() {
    const { user } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const doLogout = () => {
            if (!user) {
                navigate('/login');
            } else {
                setTimeout(() => {
                    dispatch(authLogout());
                }, 2000);
            }
        }

        doLogout();
    }, [dispatch, navigate, user]);

    return (
        <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
            <lottie-player
                src="/lotties/logout.json"
                background="transparent"
                speed="1"
                style={{ width: 280, height: 280 }}
                loop
                autoPlay
            ></lottie-player>
            <h4 className="text-center">Logout from app</h4>
        </div>
    )
}

export default Logout
