import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../../utils/api";

const initialState = {
   list: [],
   fetching: true,
   search: '',
   filter: {
      group_id: '',
      tags: '',
      muted: 0
   }
}

export const fetchDeviceList = createAsyncThunk(
   'device/fetchDeviceList',
   async (param = "", { getState }) => {
      const { search, filter: { group_id, tags, muted } } = getState().device;
      const result = await getRequest(`/device${param}&muted=${muted}&search=${search}&group_id=${group_id}&tags=${tags}`);
      return result;
   }
);

export const fetchDeviceItem = createAsyncThunk(
   'device/fetchDeviceItem',
   async (id) => {
      const result = await getRequest(`/device/${id}`);
      return result;
   }
)

export const insertDevice = createAsyncThunk(
   'device/insertDevice',
   async (data, { dispatch }) => {
      const result = await postRequest(`/device/insert`, data);
      dispatch(addItem({ ...result.data, tags: data.tags }));
      return result;
   }
)

export const updateDevice = createAsyncThunk(
   'device/updateDevice',
   async (data, { dispatch }) => {
      const { id } = data;
      const result = await putRequest(`/device/update/${id}`, data);
      dispatch(updateItem({ ...result.data, tags: data.tags }));
      return result;
   }
)

export const deleteDevice = createAsyncThunk(
   'device/deleteDevice',
   async (id, { dispatch, getState }) => {
      const data = getState().device.list.data.find(item => item.id === id);
      const result = await deleteRequest(`/device/delete/${id}`);
      dispatch(removeItem(data));
      return result;
   }
);

export const bulkDeleteDevice = createAsyncThunk(
   'device/bulkDeleteDevice',
   async (ids) => {
      const result = await deleteRequest(`/device/bulk/delete?id=${ids}`);
      return result;
   }
);

export const bulkUpdateDevice = createAsyncThunk(
   'device/bulkUpdateDevice',
   async (data) => {
      const result = await putRequest(`/device/bulk/update?id=${data.ids}`, data.form);
      return result;
   }
)

export const muteDevice = createAsyncThunk(
   'device/muteDevice',
   async (data, { dispatch }) => {
      const result = await patchRequest(`/device/mute/${data.id}`, data);
      dispatch(updateItem({ ...result.data, tags: data.tags }));
      return result;
   }
)

export const bulkMuteDevice = createAsyncThunk(
   'device/bulkMuteDevice',
   async (data) => {
      const result = await patchRequest(`/device/bulk/mute?id=${data.ids}`, data.form);
      return result;
   }
)

const deviceSlice = createSlice({
   name: 'device',
   initialState,
   reducers: {
      setList: (state, action) => {
         state.list = action.payload
      },
      addItem: (state, action) => {
         const data = [action.payload, ...state.list.data];
         state.list.data = data;
      },
      removeItem: (state, action) => {
         const removedData = state.list.data.filter((item) => item.id !== action.payload.id);
         state.list.data = removedData;
      },
      updateItem: (state, action) => {
         const data = state.list.data;
         const updatedData = data.map((item) => {
            if (item.id === action.payload.id) {
               return { ...action.payload };
            }
            return item;
         });
         state.list.data = updatedData;
      },
      setSearch: (state, action) => {
         state.search = action.payload;
      },
      setFilter: (state, action) => {
         state.filter[action.payload.name] = action.payload.value;
      },
      resetFilter: (state) => {
         state.filter = {
            group_id: '',
            tags: ''
         };
         state.search = "";
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchDeviceList.fulfilled, (state, action) => {
            state.list = action.payload;
            state.fetching = false;
         })
         .addCase(fetchDeviceList.rejected, (state) => {
            state.fetching = false;
         });
   }
});

export const { setList, addItem, removeItem, updateItem, setSearch, setFilter, resetFilter } = deviceSlice.actions;
export default deviceSlice.reducer;