import React from 'react'
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
            <h1 className="text-center mb-4">404 | Not Found</h1>
            <p>The page you are accessing was not found</p>
            <Link to="/" className="btn btn-primary">
                <FiArrowLeft className="me-1" />
                Back to Home
            </Link>
        </div>
    )
}

export default NotFound;
