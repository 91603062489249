import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "../../utils/api";
import { saveStorage, removeStorage, getStorage } from "../../utils/storage";

const initialState = {
  user: getStorage("userData"),
};

export const authLogin = createAsyncThunk(
  "auth/doLogin",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const result = await postRequest(`/auth/login`, data);
      dispatch(setUser(result));
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authLogout = createAsyncThunk(
  "auth/doLogout",
  async (data = null, { dispatch, rejectWithValue }) => {
    try {
      const result = await postRequest(`/auth/logout`, null);
      dispatch(removeUser());
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      saveStorage("userData", action.payload);
      state.user = action.payload;
    },
    setUserData: (state, action) => {
      let { id } = action.payload;
      if (state.user.data.id === id) {
        let updateUserData = {
          ...state.user,
          data: action.payload
        }
        saveStorage("userData", updateUserData);
        state.user = updateUserData;
      }
    },
    setRoleData: (state, action) => {
      let { id } = action.payload;
      if (state.user.data.role_id === id) {
        let updateRoleData = {
          ...state.user,
          data: {
            ...state.user.data,
            role: action.payload
          }
        }
        saveStorage("userData", updateRoleData);
        state.user = updateRoleData;
      }
    },
    removeUser: (state) => {
      removeStorage("userData");
      state.user = undefined;
    },
  },
});

export const { setUser, removeUser, setUserData, setRoleData } = authSlice.actions;
export default authSlice.reducer;
