import { configureStore } from '@reduxjs/toolkit';
import authReducer from './modules/auth';
import themeReducer from './modules/theme';
import tagReducer from './modules/tag';
import groupReducer from './modules/group';
import deviceReducer from './modules/device';
import logReducer from './modules/log';
import userReducer from './modules/user';
import roleReducer from './modules/role';
import monitorReducer from './modules/monitor';
import userActivityReducer from './modules/user-activity';

const store = configureStore({
    reducer: {
        auth: authReducer,
        theme: themeReducer,
        tag: tagReducer,
        group: groupReducer,
        device: deviceReducer,
        log: logReducer,
        user: userReducer,
        role: roleReducer,
        monitor: monitorReducer,
        userActivity: userActivityReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;