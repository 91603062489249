import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/api";
import { setRoleData } from './auth';

const initialState = {
   list: [],
   fetching: true,
   search: ''
}

export const fetchRoleList = createAsyncThunk(
   'role/fetchRoleList',
   async (param = "", { getState }) => {
      const { search } = getState().role;
      const result = await getRequest(`/role${param}&search=${search}`);
      return result;
   }
);

export const fetchRoleItem = createAsyncThunk(
   'role/fetchRoleItem',
   async (id) => {
      const result = await getRequest(`/role/${id}`);
      return result;
   }
)

export const insertRole = createAsyncThunk(
   'role/insertRole',
   async (data, { dispatch, rejectWithValue }) => {
      try {
         const result = await postRequest(`/role`, data);
         dispatch(addItem(result.data));
         return result;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
)

export const updateRole = createAsyncThunk(
   'role/updateRole',
   async (data, { getState, dispatch, rejectWithValue }) => {
      try {
         const { id } = data;
         const { user } = getState().auth;
         const result = await putRequest(`/role/${id}`, data);
         dispatch(updateItem(result.data));
         if (user.data.role_id === id) {
            dispatch(setRoleData(result.data));
            setTimeout(() => {
               window.location.reload();
            }, 1000);
         }
         return result;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
)

export const deleteRole = createAsyncThunk(
   'role/deleteRole',
   async (id, { dispatch, getState, rejectWithValue }) => {
      try {
         const data = getState().role.list.data.find(item => item.id === id);
         const result = await deleteRequest(`/role/${id}`);
         dispatch(removeItem(data));
         return result;
      } catch (error) {
         return rejectWithValue(error);
      }
   }
);

const roleSlice = createSlice({
   name: 'role',
   initialState,
   reducers: {
      setList: (state, action) => {
         state.list = action.payload
      },
      addItem: (state, action) => {
         const data = [action.payload, ...state.list.data];
         state.list.data = data;
      },
      removeItem: (state, action) => {
         const removedData = state.list.data.filter((item) => item.id !== action.payload.id);
         state.list.data = removedData;
      },
      updateItem: (state, action) => {
         const data = state.list.data;
         const updatedData = data.map((item) => {
            if (item.id === action.payload.id) {
               return { ...action.payload };
            }
            return item;
         });
         state.list.data = updatedData;
      },
      setSearch: (state, action) => {
         state.search = action.payload
      }
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchRoleList.fulfilled, (state, action) => {
            state.fetching = false;
            state.list = action.payload;
         })
         .addCase(fetchRoleList.rejected, (state) => {
            state.fetching = false;
         });
   }
});

export const { setList, addItem, removeItem, updateItem, setSearch } = roleSlice.actions;
export default roleSlice.reducer;