import { FiLayers, FiServer, FiTag, FiDownloadCloud, FiFile, FiLock, FiUser, FiUsers } from 'react-icons/fi';

const menuList = [
    {
        name: "device",
        url: "/",
        label: "Devices",
        icon: <FiServer className="me-2" />
    },
    {
        name: "group",
        url: "/group",
        label: "Groups",
        icon: <FiLayers className="me-2" />
    },
    {
        name: "tag",
        url: "/tag",
        label: "Tags",
        icon: <FiTag className="me-2" />
    },
    {
        name: "log",
        url: "/log",
        label: "Logs",
        icon: <FiDownloadCloud className="me-2" />
    },
    {
        dropdown: true,
        title: <DropdownUserTitle />,
        id: "dropdown-user",
        menu: [
            {
                name: "role",
                url: "/role",
                label: "Role",
                icon: FiLock
            },
            {
                name: "user",
                url: "/user",
                label: "User",
                icon: FiUser
            },
            {
                name: "user_activity",
                url: "/user-activity",
                label: "User Activity",
                icon: FiFile
            }
        ]
    }
];

function DropdownUserTitle() {
    return (
        <div className="d-flex justify-content-lg-center align-items-center">
            <FiUsers className="me-2" /> 
            <span>User</span>
        </div>
    )
}

export default menuList;