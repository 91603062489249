import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dark: false
}

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducer: {
        setTheme: (state, action) => {
            state.dark = action.payload
        }
    }
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;