import { useEffect, useState } from "react";

const useScroll = () => {
    const [showScroll, setShowScroll] = useState(false);
    const scrollAnimation = {
        visible: {
            y: 0,
            opacity: 1,
        },
        hidden: {
            y: -200,
            opacity: 0,
        }
    }

    const scrollTopToggle = () => {
        if (window.scrollY > 10) {
            setShowScroll(true);
        } else {
            setShowScroll(false);
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollTopToggle);

        return () => {
            document.removeEventListener('scroll', scrollTopToggle);
        }
    }, []);

    return [scrollAnimation, showScroll];
}

export default useScroll;