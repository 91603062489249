import React, { lazy, Suspense, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import Main from './components/layouts/Main';
import PageBreadcrumb from './components/widgets/PageBreadcrumb';
import NotFound from './pages/errors/NotFound';
import Forbidden from './pages/errors/Forbidden';
import delayed from 'p-min-delay';
import { useSelector } from 'react-redux';
import Logout from './pages/Logout';
import useAuthPermission from './hooks/useAuthPermission';

const Login = lazy(() => delayed(import('./pages/Login'), 1000));
const Tag = lazy(() => delayed(import('./pages/Tag'), 1000));
const Group = lazy(() => delayed(import('./pages/Group'), 1000));
const Device = lazy(() => delayed(import('./pages/Device'), 1000));
const Log = lazy(() => delayed(import('./pages/Log'), 1000));
const Role = lazy(() => delayed(import('./pages/Role'), 1000));
const User = lazy(() => delayed(import('./pages/User'), 1000));
const UserActivity = lazy(() => delayed(import('./pages/UserActivity'), 1000));

function App() {
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="/login" exact element={<Login />} />
          <Route path="/logout" exact element={<Logout />} />
          <Route path="/" element={<Main />}>
            <Route index element={<Page name="device" title="Device" Component={Device} />} />
            <Route path="tag" element={<Page name="tag" title="Tag" Component={Tag} />} />
            <Route path="group" element={<Page name="group" title="Group" Component={Group} />} />
            <Route path="log" element={<Page name="log" title="Log" Component={Log} />} />
            <Route path="role" element={<Page name="role" title="Role" Component={Role} />} />
            <Route path="user" element={<Page name="user" title="User" Component={User} />} />
            <Route path="user-activity" element={<Page name="user_activity" title="User Activity" Component={UserActivity} />} />
          </Route>
          <Route path="/forbidden" exact element={<Forbidden />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  )
}

function Page({ name, title, Component }) {
  const { permissionCheck } = useAuthPermission();

  if (permissionCheck(name)) {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <PageBreadcrumb title={title} />
        </div>
        <Component />
      </>
    )
  }

  return <Navigate to="/forbidden" />;
}

function PageLoader() {
  return (
    <div className="d-flex justify-content-center align-items-center position-fixed top-0 left-0 w-100 h-100 bg-light">
      <Spinner animation="grow" />
    </div>
  )
}

export default App
