import React from 'react'
import { Link } from 'react-router-dom'

function PageBreadcrumb({ title }) {
   return (
      <div>
         <nav className="d-flex align-items-center" aria-label="breadcrumb">
            <ol className="mb-0 breadcrumb">
               <li className="breadcrumb-item">
                  <Link className="text-decoration-none text-white" to="/">Home</Link>
               </li>
               <li className="breadcrumb-item active" aria-current="page">{title}</li>
            </ol>
         </nav>
      </div>
   )
}

export default PageBreadcrumb
