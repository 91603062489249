import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useAuthPermission = () => {
    const { user } = useSelector(state => state.auth);
    const [permissions, setPermissions] = useState(user?.data ? user.data.role.module_roles : []);
    
    const permissionCheck = (name) => {
        const check = permissions.find(item => item.role_module.name === name);
        if (check) {
            return true;
        }
        return false;
    }

    const dropdownPermissionCheck = (menus) => {
        const menuData = menus.reduce((acc, cur) => {
            return [...acc, cur.name];
        }, []);
        const check = permissions.find(item => menuData.includes(item.role_module.name));
        return typeof check !== "undefined" ? true : false;
    }

    const featureCheck = (url, featureName) => {
        const moduleCheck = permissions.find(item => item.role_module.url === url);
        if (moduleCheck) {
            const featureCheck = moduleCheck.module_role_permissions.find(feature => feature.name === featureName);
            if (featureCheck) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    useEffect(() => {
        if (user?.data) {
            setPermissions(user.data.role.module_roles);
        }
    }, [user]);

    return { permissionCheck, dropdownPermissionCheck, featureCheck };
}

export default useAuthPermission;